var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-toolbar',{attrs:{"elevation":"1"}},[_c('v-toolbar-title',{staticClass:"text-h5 font-weight-regular title"},[_vm._v("MFS List")]),_c('v-spacer'),_c('v-btn',{staticClass:"mr-3",attrs:{"outlined":""},on:{"click":function($event){_vm.showGenerateModal = true}}},[_c('v-icon',{staticClass:"mr-1"},[_vm._v("mdi-family-tree")]),_c('div',{staticClass:"reload-text"},[_vm._v("Generate Report")])],1),_c('v-btn',{attrs:{"outlined":""},on:{"click":function () { return this$1.fetchRegistrations(); }}},[_c('v-icon',{staticClass:"mr-1"},[_vm._v("mdi-reload")]),_c('div',{staticClass:"reload-text"},[_vm._v("Reload")])],1)],1),_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{staticClass:"elevation-2",staticStyle:{"cursor":"pointer"},attrs:{"loading":_vm.loading,"headers":_vm.headers,"disable-sort":"","disable-filtering":"","items":_vm.registrations,"page":_vm.page,"items-per-page":_vm.itemsPerPage,"server-items-length":_vm.totalItems,"item-key":"id","id":"match-table","footer-props":_vm.footerProps},on:{"update:page":function($event){_vm.page=$event},"update:itemsPerPage":function($event){_vm.itemsPerPage=$event},"update:items-per-page":function($event){_vm.itemsPerPage=$event},"click:row":function (item) { return _vm.openDetailsOf(item); }},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('div',{staticClass:"pa-2 lighten-2 d-lg-flex align-center"},[_c('v-btn',{staticClass:"ma-1 pa-5",attrs:{"color":"primary"},on:{"click":function($event){_vm.showModal = !_vm.showModal}}},[_c('v-icon',[_vm._v("mdi-filter")]),_vm._v(" Filter ")],1),_c('v-combobox',{staticClass:"chips-combo search-field ma-1",attrs:{"solo":"","flat":"","chips":"","multiple":"","label":"Click filter button to apply filter","readonly":"","hide-details":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var attrs = ref.attrs;
var item = ref.item;
return [_c('v-chip',_vm._b({},'v-chip',attrs,false),[_c('strong',[_vm._v(_vm._s(item))]),_vm._v("   "),_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.removeFilter(item)}}},[_vm._v(" $delete")])],1)]}}]),model:{value:(_vm.filterChips),callback:function ($$v) {_vm.filterChips=$$v},expression:"filterChips"}})],1),_c('v-divider')]},proxy:true},{key:"footer.prepend",fn:function(){return [_c('div',{staticClass:"pa-2 lighten-2 d-lg-flex align-center"},[_c('v-btn',{staticClass:"ma-1",attrs:{"color":"success"},on:{"click":_vm.downloadAsCsv}},[_vm._v("Download as CSV")])],1)]},proxy:true},{key:"item.mobileNo",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"small":"","outlined":"","to":{ name: 'user.view', params: { id: item.userId } }}},[_vm._v(" "+_vm._s(item.mobileNo)+" ")])]}},{key:"item.createdAt",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.createdAt))+" ")]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [(item.status)?_c('v-chip',{attrs:{"small":""}},[_vm._v(_vm._s(item.status))]):_vm._e()]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"outlined":"","small":""},on:{"click":function($event){return _vm.openDetailsOf(item)}}},[_vm._v(" More")])]}}],null,true)})],1)],1),_c('v-dialog',{attrs:{"max-width":"900px","scrollable":""},model:{value:(_vm.showModal),callback:function ($$v) {_vm.showModal=$$v},expression:"showModal"}},[_c('filters',{attrs:{"initial-values":_vm.filterValues},on:{"filterSelected":_vm.filterSelected}})],1),_c('v-dialog',{attrs:{"max-width":"600px","scrollable":""},model:{value:(_vm.showGenerateModal),callback:function ($$v) {_vm.showGenerateModal=$$v},expression:"showGenerateModal"}},[_c('generate-report',{on:{"close":function($event){_vm.showGenerateModal = false}}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }