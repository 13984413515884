<template>
  <v-container>
    <v-toolbar elevation="1">
      <v-toolbar-title class="text-h5 font-weight-regular title">MFS List</v-toolbar-title>
      <v-spacer />
      <v-btn outlined @click="showGenerateModal = true" class="mr-3">
        <v-icon class="mr-1">mdi-family-tree</v-icon>
        <div class="reload-text">Generate Report</div>
      </v-btn>
      <v-btn outlined @click="() => this.fetchRegistrations()">
        <v-icon class="mr-1">mdi-reload</v-icon>
        <div class="reload-text">Reload</div>
      </v-btn>
    </v-toolbar>
    <v-row justify="center">
      <v-col cols="12">
        <v-data-table
          :loading="loading"
          :headers="headers"
          disable-sort
          disable-filtering
          :items="registrations"
          :page.sync="page"
          :items-per-page.sync="itemsPerPage"
          :server-items-length="totalItems"
          item-key="id"
          class="elevation-2"
          id="match-table"
          :footer-props="footerProps"
          @click:row="(item) => openDetailsOf(item)"
          style="cursor: pointer"
        >
          <template v-slot:top>
            <div class="pa-2 lighten-2 d-lg-flex align-center">
              <v-btn color="primary" class="ma-1 pa-5" @click="showModal = !showModal">
                <v-icon>mdi-filter</v-icon> Filter
              </v-btn>
              <v-combobox
                v-model="filterChips"
                solo
                flat
                chips
                multiple
                label="Click filter button to apply filter"
                class="chips-combo search-field ma-1"
                readonly
                hide-details
              >
                <template v-slot:selection="{ attrs, item }">
                  <v-chip v-bind="attrs">
                    <strong>{{ item }}</strong> &nbsp;
                    <v-icon small @click="removeFilter(item)"> $delete</v-icon>
                  </v-chip>
                </template>
              </v-combobox>
            </div>
            <v-divider />
          </template>
          <template v-slot:[`footer.prepend`]>
            <div class="pa-2 lighten-2 d-lg-flex align-center">
              <v-btn color="success" class="ma-1" @click="downloadAsCsv">Download as CSV</v-btn>
            </div>
          </template>
          <template v-slot:[`item.mobileNo`]="{ item }">
            <v-btn small outlined :to="{ name: 'user.view', params: { id: item.userId } }">
              {{ item.mobileNo }}
            </v-btn>
          </template>
          <template v-slot:[`item.createdAt`]="{ item }">
            {{ formatDate(item.createdAt) }}
          </template>
          <template v-slot:[`item.status`]="{ item }">
            <v-chip small v-if="item.status">{{ item.status }}</v-chip>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-btn outlined small @click="openDetailsOf(item)"> More</v-btn>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <v-dialog v-model="showModal" max-width="900px" scrollable>
      <filters @filterSelected="filterSelected" :initial-values="filterValues" />
    </v-dialog>
    <v-dialog v-model="showGenerateModal" max-width="600px" scrollable>
      <generate-report @close="showGenerateModal = false" />
    </v-dialog>
  </v-container>
</template>

<script>
import { ONBOARDING_MFS_GENERATE_REPORT, ONBOARDING_MFS_REGISTRATIONS } from "@/constants/api";
import Filters from "@/views/pages/dashboard/mfs/Filters";
import GenerateReport from "@/views/pages/dashboard/mfs/GenerateReport";
import _ from "lodash";
import moment from "moment";

export default {
  name: "MFSIndex",
  metaInfo: { title: "Onboarding (MFS)" },
  components: { Filters, GenerateReport },
  data: () => ({
    loading: false,
    showModal: false,
    showGenerateModal: false,
    headers: [
      { text: "Type", value: "type" },
      { text: "Mobile No", value: "mobileNo" },
      { text: "NID", value: "nid", width: 150 },
      { text: "Date of Birth", value: "dateOfBirth", width: 120 },
      { text: "First Name", value: "firstName", width: 250 },
      { text: "Last Name", value: "lastName", width: 150 },
      { text: "Created At", value: "createdAt", width: 200 },
      { text: "Status", value: "status" },
      { text: "", value: "actions" },
    ],
    registrations: [],
    totalItems: null,
    optionsLength: 6,
    footerProps: { "items-per-page-options": [15, 30, 60, 100] },
    filterValues: {},
    filterChips: [],
  }),
  computed: {
    page: {
      get() {
        return Number(this.$route.query.page) || 1;
      },
      set(val, old) {
        if (val !== old) this.pushCurrentRoute({ page: val });
      },
    },
    itemsPerPage: {
      get() {
        return Number(this.$route.query.limit) || 15;
      },
      set(val, old) {
        if (val !== old) this.pushCurrentRoute({ page: 1, limit: val });
      },
    },
  },
  watch: {
    $route({ name }) {
      if (name === "mfs.index") {
        this.fetchRegistrations();
      }
    },
  },
  mounted() {
    this.fetchRegistrations();
  },
  methods: {
    openDetailsOf(item) {
      this.$router.push({ name: "mfs.view", params: { id: item.id } });
    },
    async pushCurrentRoute({ page, limit }) {
      await this.$nextTick();
      const q = {};
      q.page = page === undefined ? this.page : page;
      q.limit = limit === undefined ? this.itemsPerPage : limit;
      Object.keys(q).forEach((el) => {
        if (!q[el]) delete q[el];
      });

      this.$router.push({ name: this.$route.name, query: q }).catch(() => {});
    },
    async fetchRegistrations() {
      this.loading = true;
      try {
        this.registrations = [];
        const params = {
          page: this.page - 1,
          limit: this.itemsPerPage,
        };
        const response = await this.$axios.post(ONBOARDING_MFS_REGISTRATIONS, this.filterValues, {
          params,
        });
        if (response.data.code !== 0) {
          throw new Error(response.data.message);
        }
        this.totalItems = response.data.data.totalItems;
        this.registrations = response.data.data.items.map((item) => ({
          ...item,
          createdAt: new Date(item.createdAt),
        }));
      } catch (err) {
        this.$iziToast.showError(err);
      } finally {
        this.loading = false;
      }
    },
    async downloadAsCsv() {
      this.loading = true;
      try {
        const response = await this.$axios.post(ONBOARDING_MFS_GENERATE_REPORT, this.filterValues);
        if (response.data.code !== 0) {
          throw new Error(response.data.message);
        }
        this.downloadCsvFile(response.data.data, "onboarding_export_" + moment().toISOString());
      } catch (err) {
        this.$iziToast.showError(err);
      } finally {
        this.loading = false;
      }
    },
    downloadCsvFile(data, name) {
      let aTag = window.document.createElement("a");
      aTag.href = window.URL.createObjectURL(new Blob([data], { type: "text/csv" }));
      aTag.download = `${name}.csv`;
      document.body.appendChild(aTag);
      aTag.click();
      document.body.removeChild(aTag);
    },
    filterSelected(data) {
      this.showModal = false;
      this.filterValues = data;
      this.updateChips();
    },
    prettify(name) {
      return _.startCase(name);
    },
    updateChips() {
      this.filterChips = [];
      Object.keys(this.filterValues).forEach((el) => {
        if (this.filterValues[el]) {
          this.filterChips.push(`${this.prettify(el)}: ${this.filterValues[el]}`);
        }
      });
      this.fetchRegistrations();
    },
    async removeFilter(item) {
      try {
        let toBeRemovedKey = item.split(":")[0];
        if (toBeRemovedKey) {
          this.$delete(this.filterValues, _.camelCase(toBeRemovedKey));
          await this.$nextTick();
          this.updateChips();
        }
      } catch (err) {
        console.log(err);
      }
    },
  },
};
</script>

<style lang="scss">
.chips-combo {
  .v-input__append-inner {
    display: none !important;
  }
}
</style>
